
import React from "react"
import Layout from "../components/Layout"
import { Link, graphql } from "gatsby"
import SEO from "../components/SEO"
import { formatTimestamp } from '../utils/dates'

import RecipeGrid from "../components/RecipeGrid"
import RecipeGridHeaderBlock from "../components/RecipeGridHeaderBlock"

export default function Tag({ pageContext, data, location }) {
  const { tag } = pageContext;
  const { nodes, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${tag}`;
  return (
    <Layout>
      <SEO
        title="Tags"
        description="All the recipes from Andrew Johnson"
        keywords={[`recipes`, `baking`, `Andrew Johnson`]}
        pathname={location.pathname}
      />
      <main className="space-y-6 mx-auto container px-4 pb-8">
        <RecipeGridHeaderBlock colSpan={1}>
          <h2 className="self-start font-bold">
            {tagHeader}
          </h2>
        </RecipeGridHeaderBlock>
        <RecipeGrid recipes={nodes}></RecipeGrid>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($tag: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          date
          description
          featured {
            childImageSharp {
              gatsbyImageData(width: 1024, aspectRatio: 1)
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`

import React from 'react';

const RecipeGridHeaderBlock = ({children, colSpan}) => {
  return (
    <div
      className={`flex pr-4 pb-4 -mb-4 gap-0 col-span-${
        colSpan || 1
      } w-full overflow-hidden`}
      style={{ overflowWrap: "break-word" }}
    >
      <div
        className="bg-white text-black border-pride border-8 text-4xl p-8 flex flex-col w-full items-start sm:items-end w-full"
        style={{ boxShadow: "12px 12px 0 0 rgba(0,0,0,0.1)" }}
      >
        <div
          className="flex flex-col items-end flex-shrink-1 flex-grow-0 w-full"
          style={{ overflowWrap: "break-word" }}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default RecipeGridHeaderBlock;

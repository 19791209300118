import React from "react"

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { formatTimestamp } from "../utils/dates"

const RecipeGridTile = ({ recipe }) => {
  let { slug } = recipe.fields
  let { date, title, description, featured } = recipe.frontmatter
  const image = getImage(featured)

  return (
    <article key={recipe.fields.slug} className="overflow-hidden">
      <header>
        <Link
          to={`/recipes${recipe.fields.slug}`}
          className="bg-white -mb-8 hover:no-underline"
        >
          <GatsbyImage image={image} alt={recipe.frontmatter.description} />
          <div className="relative -top-8">
            <small className="inline-block px-2 align-middle bg-yellow-300 font-mono pride-background relative leading-8">
              {formatTimestamp(recipe.frontmatter.date)}
            </small>
            <div className="-mb-8 mt-2">
              <h3 className="font-bold text-2xl hover:underline inline">
                {recipe.frontmatter.title}
              </h3>
            </div>
          </div>
        </Link>
      </header>
    </article>
  )
}

export default RecipeGridTile

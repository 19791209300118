import React from "react"
import RecipeGridTile from "./RecipeGridTile"

const RecipeGrid = ({ recipes, children }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
      {children}
      {recipes.map(recipe => (
        <RecipeGridTile recipe={recipe} key={recipe.fields.slug} />
      ))}
    </div>
  )
}

export default RecipeGrid;
